<template lang="pug">
  main.white-bg
    .inside-content
      .container
        article.article
          .article__header
            .article__header-info
              .article__header-content
                h1 Расчет теплого пола онлайн

              .article__header-img
                img(src="~images/blog/warm-floor.svg" style="margin: 15% auto; width: 40%")
                //-                             0        768        992          1280
                //+img("i/article-img", "jpg", [719,       943,       1231,         1280], "")

          .article__content
            .article__sidebar
              .article__meta
                .article__meta-item
                  span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                  span.article__meta-item-text Время чтения 5 минут

            .article__content-text

              h2 Расчет водяного теплого пола онлайн
              p
                strong Содержание
              ol
                li Почему важно сделать расчет перед монтажом?
                li Что вы получаете в результате расчета?
                li Какие данные нужны для расчета?
                li Последователность выполнения расчета
                li Выводы

              h2 Почему важно сделать расчет теплого пола перед монтажом?
              p Водяной теплый пол — это залитые в бетон трубы. Если что-то сделано неправильно, устранение ошибки обойдется очень дорого.
              p
                strong В домах, где теплый пол сделан без расчета, сталкиваются с такими проблемами:

              ul
                li Большая плата за отопление — неправильно подобран слой теплоизоляции под бетонной стяжкой.
                li Некомфортная температура пола — неправильно заданы температурные режимы для помещений с разным назначением.
                li Неравномерный прогрев, “полосатый” пол — неправильно задан слой бетона над трубой, шаг укладки   трубы.
                li Мало тепла от пола — неправильно выбран материал стяжки и напольное покрытие, что приводит к                 крайне низкому уровню теплоотдачи пола.
                li Теплый пол не справляется в самые холодные дни, а температура в помещениях падает на несколько градусов, при этом дополнительные источники тепла отсутствуют.
                li Холодные участки пола — завоздушивание веток теплого пола.
                li Рассыхание ламината — не были соблюдены ограничения допустимых температурных режимов.

              blockquote
                h2 Расчет теплого пола – это страховка от ошибок
                p За эту страховку вы платите один раз, а пользуетесь всю жизнь.

              h2 Что даст вам расчет теплого пола?

              p
                strong Главное — вы узнаете, в каких помещениях хватит теплоотдачи теплого пола, а в каких нужно добавлять радиаторы или конвекторы.
              p Вы определите минимальную допустимую толщину теплоизоляции под теплым полом, чтобы сократить потерю тепла вниз или не перегреть нижерасположенное жилое помещение.
              p В результатах расчета вы найдете все данные для монтажа теплого пола:
              ul
                li количество веток, диаметр и шаг укладки трубы;
                li температуру подачи в ветку, перепад температур и расход на каждой ветке.
              p Зная суммарный расход теплоносителя по всем веткам и максимальное гидравлическое сопротивление, вы сможете правильно подобрать циркуляционный насос для теплого пола.
              p Расходы по веткам (контурам) мы показываем в литрах в минуту, чтобы было удобно балансировать коллектор теплого пола. Практически у всех производителей коллекторов расходомеры градуированы в литрах в минуту.

              h2 Какие данные нужны для расчета?

              p Перед началом расчета нужно определить места установки глухой стационарной мебели и распределительного коллектора. Нет смысла делать теплый пол под кухонным гарнитуром, потому что мебель будет блокировать теплоотдачу.
              p Распределительный коллектор теплых полов устанавливается обычно в котельной, санузлах, вспомогательных нежилых помещениях или под лестницей.
              p Вы должны знать, каким будет напольное покрытие во всех комнатах. Покрытие должно хорошо проводить тепло. Керамика лучше, чем ламинат. Ламинат лучше, чем паркет. Деревянные покрытия имеют ограничения по нагреву. При высоких температурах они просто рассыхаются. Если хотите обогревать дом только теплым полом, смотрите в сторону керамических напольных покрытий.

              h2 Последовательность выполнения расчета теплых полов

              p Сперва вам нужно создать и описать конструкцию (пирог) теплого пола.
              p У вас может быть несколько разных конструкций теплых полов.
              p Пример: теплый пол на 1 этаже, теплый пол на 2 этаже, теплый пол в гараже.

              p
                img(src="~images/blog/article-2-1.jpg" loading="lazy")

              p Далее эти конструкции теплого пола прикрепляем к помещениям, в которых планируем напольное отопление.
              p Прикрепляются конструкции путем добавления в помещение пластин теплого пола.
              p Пластиной теплого пола мы называем бетонную панель с трубой внутри, уложенной змейкой или улиткой. Внутри пластины не может быть соединений или разветвлений трубы. Саму трубу мы называем веткой теплого пола. А трубы от пластины до коллектора — подводками.

              p
                img(src="~images/blog/article-2-2.jpg" loading="lazy")

                //-                             0        768        992          1280
                //+img("i/article-2-2", "jpg", [719,     866,       866,         866], "")

              p На плане этажа вам необходимо условно поделить площадь помещений на пластины и обозначить место установки распределительного коллектора.

              p
                img(src="~images/blog/article-2-3.jpg" loading="lazy")
                //-                             0        768        992          1280
                //+img("i/article-2-3", "jpg", [501,     501,       501,         501], "")

              p Пластина не может превышать 8 метров ни в одном из измерений. Это значит, что в помещении может быть одна или несколько пластин теплого пола. Бывают случаи, когда одна пластина обогревает два или три смежных помещения. Здесь все зависит от площади и назначения помещений.
              p Для каждой пластины теплого пола задаем параметры:
              ul
                li Площадь.
                li Температуру поверхности пола..
                li Выбираем трубу из справочника (у нас только проверенные и надежные бренды).
                li Вводим шаг укладки и длину подводок.

              p
                img(src="~images/blog/article-2-4.jpg" loading="lazy")
                //-                             0        768        992          1280
                //- +img("i/article-2-4", "jpg", [674,     674,       674,         674], "")

                //-                             0        768        992          1280
                //+img("i/article-2-4", "jpg", [600,     600,       600,         600], "")

              h2 Мы сразу показываем все результаты расчета пластины теплого пола:
              ul
                li теплоодачу лицевую для всей пластины и удельную с 1м
                  sup 2&nbsp;
                  | пола.
                li теплоотдачу тыльную (теплопотери вниз)
                li температуры подачи и обратки, а также перепад температур
                li гидравлическое сопротивление
                li суммарную длину ветки в метрах

              p
                img(src="~images/blog/article-2-5.png" loading="lazy" style="border-radius: 15px")

                //-                             0        768        992          1280
                //+img("i/article-2-5", "jpg", [718,     880,       880,         880], "")

              h2 Выводы и рекомендации
              p 1. Наш калькулятор расчета теплого пола позволяет подобрать оптимальные параметры, чтобы и теплоотдача не пострадала, и трубы много не потребовалось.
              p 2. Вы сможете моделировать разные режимы работы теплого пола и наблюдать за тем, при какой температуре за окном системы уже не хватает для поддержания оптимальной температуры воздуха в комнатах.

              .download(id="lightgallery")
                .download__content
                  .download__text
                    | 4. Результат расчета теплого пола формируется в виде ведомости, оформленной по ГОСТ. В штампе вы можете указать свое имя, как разработчика.
                  a.download__text-link(href="/warm_floors.pdf", target="_blank") скачать (.PDF 135kb)
                .download__img
                  enlargeable-image(:src="downloadImg" :src_large="downloadImg" animation_duration="300")
                    span
                      img(src="~/images/blog/floor-result-small.png", alt="")

                    //-                             0        768        992          1280
                    //+img("i/article-doc-2", "jpg", [221,     221,       221,         221], "")

              .article__register
                a.button.button_green(@click="goSignUp") Регистрация

              //.article__likes
                .article__likes-title Этот материал был вам полезен?
                .article__likes-buttons
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Да
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 9.75h4.5v9.75H3a.75.75 0 01-.75-.75V10.5A.75.75 0 013 9.75z" fill="#696E8A"/><path d="M21.74 7.51a2.25 2.25 0 00-1.69-.76H15v-1.5a3.75 3.75 0 00-3.75-3.75.75.75 0 00-.67.41L7.04 9H3a1.5 1.5 0 00-1.5 1.5v8.25a1.5 1.5 0 001.5 1.5h15.93a2.25 2.25 0 002.23-1.97l1.12-9a2.25 2.25 0 00-.54-1.77zM3 10.5h3.75v8.25H3V10.5zm17.8-1.4l-1.13 9a.75.75 0 01-.74.65H8.25V9.93l3.44-6.89a2.25 2.25 0 011.8 2.21V7.5a.75.75 0 00.76.75h5.8a.75.75 0 01.74.84z" fill="#696E8A"/></svg>
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Нет
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 14.25h4.5V4.5H3a.75.75 0 00-.75.75v8.25a.75.75 0 00.75.75z" fill="#696E8A"/><path d="M21.74 16.49a2.25 2.25 0 01-1.69.76H15v1.5a3.75 3.75 0 01-3.75 3.75.75.75 0 01-.67-.41L7.04 15H3a1.5 1.5 0 01-1.5-1.5V5.25A1.5 1.5 0 013 3.75h15.93a2.25 2.25 0 012.23 1.97l1.12 9a2.25 2.25 0 01-.54 1.77zM3 13.5h3.75V5.25H3v8.25zm17.8 1.4l-1.13-9a.75.75 0 00-.74-.65H8.25v8.82l3.44 6.89a2.25 2.25 0 001.8-2.21V16.5a.75.75 0 01.76-.75h5.8a.75.75 0 00.74-.84z" fill="#696E8A"/></svg>

        section.related
          .related__title Другие статьи

          .prev-next
            a.prev-next__item(@click="goRadiator") Предыдущая
            a.prev-next__item(@click="goHeatloss") Следующая

          flickity.related__grid(ref="flickity" :options="flickityOptions")

            article.article-card
              a.article-card__img(@click="goHeatloss")
                img(src="~images/blog/house.svg" style="width: 60%")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goHeatloss") Расчет теплопотерь
                .blog-desc
                  p Наш онлайн калькулятор теплопотерь позволяет сделать расчет в двух вариантах: Упрощенный или Подробный.
                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 3 минуты

                  a.blog-card__read-more(@click="goHeatloss")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goRadiator")
                img(src="~images/blog/radiator.svg" style="width: 50%")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goRadiator") Расчет радиаторов
                .blog-desc
                  p В советское время выбор радиатора (прибора отопления) был предопределен. Вот вам чугунная батарея – грейтесь.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 4 минуты

                  a.blog-card__read-more(@click="goRadiator")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goWhy")
                img(src="~images/blog/article-img.png")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goWhy") Зачем нужны расчеты
                .blog-desc
                  p Думаем, ни для кого не секрет, что подавляющее большинство частных домов строится не только без проекта, но и вообще без расчетов.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 10 минут

                  a.blog-card__read-more(@click="goWhy")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

</template>
<script>
import Flickity from 'vue-flickity'
import navigationMixin from 'mixins/navigation'
import EnlargeableImage from '@diracleo/vue-enlargeable-image'

export default {
  name: 'RaschetTeplogoPola',
  mixins: [navigationMixin],
  data () {
    return {
      flickityOptions: {
        groupCells: true,
        wrapAround: false,
        contain: true,
        autoPlay: false,
        adaptiveHeight: false,
        prevNextButtons: false,
        pageDots: false,
        resize: false
      },
      downloadImg: require('../../assets/images/blog/floor-result.png')
    }
  },
  components: {
    Flickity,
    EnlargeableImage
  },
  metaInfo: {
    title: 'Расчет водяного теплого пола. Онлайн-калькулятор.',
    meta: [
      {
        name: 'description',
        content: 'Определение теплоотдачи водяного теплого пола, количество и длина веток, диаметр и шаг укладки трубы, настройка.'
      }
    ]
  }
}
</script>
<style lang="sass">
  //@import "stylesheets/blog"
</style>
